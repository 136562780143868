   <!-- ========== Switcher  ========== -->
     <div id="hs-overlay-switcher" class="hs-overlay hidden ti-offcanvas ti-offcanvas-right" tabindex="-1">
    <div class="ti-offcanvas-header z-10 relative">
      <h5 class="ti-offcanvas-title">
        Switcher
      </h5>
      <button type="button"
        class="ti-btn flex-shrink-0 p-0  transition-none text-defaulttextcolor dark:text-defaulttextcolor/70 hover:text-gray-700 focus:ring-gray-400 focus:ring-offset-white  dark:hover:text-white/80 dark:focus:ring-white/10 dark:focus:ring-offset-white/10"
        data-hs-overlay="#hs-overlay-switcher">
        <span class="sr-only">Close modal</span>
        <i class="ri-close-circle-line leading-none text-lg"></i>
      </button>
    </div>
    <div class="ti-offcanvas-body !p-0 !border-b dark:border-white/10 z-10 relative !h-auto">
      <div class="flex rtl:space-x-reverse" aria-label="Tabs" role="tablist">
        <button type="button"
          class="hs-tab-active:bg-success/20 w-full !py-2 !px-4 hs-tab-active:border-b-transparent text-defaultsize border-0 hs-tab-active:text-success dark:hs-tab-active:bg-success/20 dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-success -mb-px bg-white font-semibold text-center  text-defaulttextcolor dark:text-defaulttextcolor/70 rounded-none hover:text-gray-700 dark:bg-bodybg dark:border-white/10 dark:hover:text-gray-300 active"
          id="switcher-item-1" data-hs-tab="#switcher-1" aria-controls="switcher-1" role="tab">
          Theme Style
        </button>
        <button type="button"
          class="hs-tab-active:bg-success/20 w-full !py-2 !px-4 hs-tab-active:border-b-transparent text-defaultsize border-0 hs-tab-active:text-success dark:hs-tab-active:bg-success/20 dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-success -mb-px  bg-white font-semibold text-center  text-defaulttextcolor dark:text-defaulttextcolor/70 rounded-none hover:text-gray-700 dark:bg-bodybg dark:border-white/10  dark:hover:text-gray-300"
          id="switcher-item-2" data-hs-tab="#switcher-2" aria-controls="switcher-2" role="tab">
          Theme Colors
        </button>
      </div>
    </div>
    <div class="ti-offcanvas-body" id="switcher-body">
      <div id="switcher-1" role="tabpanel" aria-labelledby="switcher-item-1" class="">
        <div class="">
          <p class="switcher-style-head">Theme Color Mode:</p>
          <div class="grid grid-cols-3 switcher-style">
            <div class="flex items-center">
              <input type="radio" name="theme-style" class="ti-form-radio" id="switcher-light-theme"  (click)="themeChange('light','light','dark')" [checked]="localdata['ynex-theme-mode']!='dark'">
              <label for="switcher-light-theme"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Light</label>
            </div>
            <div class="flex items-center">
              <input type="radio" name="theme-style" class="ti-form-radio" id="switcher-dark-theme" (click)="themeChange('dark','dark','dark')" [checked]="localdata['ynex-theme-mode']=='dark'">
              <label for="switcher-dark-theme"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold" >Dark</label>
            </div>
          </div>
        </div>
        <div>
          <p class="switcher-style-head">Directions:</p>
          <div class="grid grid-cols-3  switcher-style">
            <div class="flex items-center">
              <input type="radio" name="direction" class="ti-form-radio" id="switcher-ltr"   (click)="DirectionsChange('ltr')"
              [checked]="localdata['ynex-dir'] != 'rtl'">
              <label for="switcher-ltr" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold" >LTR</label>
            </div>
            <div class="flex items-center">
              <input type="radio" name="direction" class="ti-form-radio" id="switcher-rtl" (click)="DirectionsChange('rtl')"
              [checked]="localdata['ynex-dir'] == 'rtl'">
              <label for="switcher-rtl" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">RTL</label>
            </div>
          </div>
        </div>
        <div>
          <p class="switcher-style-head">Navigation Styles:</p>
          <div class="grid grid-cols-3  switcher-style">
            <div class="flex items-center">
              <input type="radio" name="navigation-style" class="ti-form-radio" id="switcher-vertical"   (click)="NavigationChange('vertical')"
              [checked]="localdata['ynex-nav-mode'] != 'horizontal'">
              <label for="switcher-vertical"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Vertical</label>
            </div>
            <div class="flex items-center">
              <input type="radio" name="navigation-style" class="ti-form-radio" id="switcher-horizontal"   [checked]="localdata['ynex-nav-mode'] == 'horizontal'"
              (click)="NavigationChange('horizontal')">
              <label for="switcher-horizontal"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Horizontal</label>
            </div>
          </div>
        </div>
        <div>
          <p class="switcher-style-head">Navigation Menu Style:</p>
          <div class="grid grid-cols-2 gap-2 switcher-style">
            <div class="flex">
              <input type="radio" name="navigation-data-menu-styles" class="ti-form-radio" id="switcher-menu-click"
              (click)="Menus('menu-click-closed', 'menu-click')"
              [checked]="localdata['ynex-menu-style'] == 'menu-click'">
              <label for="switcher-menu-click" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Menu
                Click</label>
            </div>
            <div class="flex">
              <input type="radio" name="navigation-data-menu-styles" class="ti-form-radio" id="switcher-menu-hover"    (click)="Menus('menu-hover-closed', 'menu-hover')"
              [checked]="localdata['ynex-menu-style'] == 'menu-hover'">
              <label for="switcher-menu-hover" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Menu
                Hover</label>
            </div>
            <div class="flex">
              <input type="radio" name="navigation-data-menu-styles" class="ti-form-radio" id="switcher-icon-click"    (click)="Menus('icon-click-closed', 'icon-click')"
              [checked]="localdata['ynex-menu-style'] == 'icon-click'">
              <label for="switcher-icon-click" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Icon
                Click</label>
            </div>
            <div class="flex">
              <input type="radio" name="navigation-data-menu-styles" class="ti-form-radio" id="switcher-icon-hover"  (click)="Menus('icon-hover-closed', 'icon-hover')"
              [checked]="localdata['ynex-menu-style'] == 'icon-hover'">
              <label for="switcher-icon-hover" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Icon
                Hover</label>
            </div>
          </div>
          <div class="px-4 text-secondary text-xs"><b class="me-2">Note:</b>Works same for both Vertical and
            Horizontal
          </div>
        </div>
        <div class=" sidemenu-layout-styles">
          <p class="switcher-style-head">Sidemenu Layout Syles:</p>
          <div class="grid grid-cols-2 gap-2 switcher-style">
            <div class="flex">
              <input type="radio" name="sidemenu-layout-styles" class="ti-form-radio" id="switcher-default-menu"  (click)="SideMenus('', 'overlay')"
              [checked]="localdata['ynex-sidemenu-styles']  == 'default-menu'|| !localdata['ynex-sidemenu-styles']&&!localdata['ynex-menu-style']" >
              <label for="switcher-default-menu"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold ">Default
                Menu</label>
            </div>
            <div class="flex">
              <input type="radio" name="sidemenu-layout-styles" class="ti-form-radio" id="switcher-closed-menu" (click)="SideMenus('close-menu-close', 'closed')"
              [checked]="localdata['ynex-sidemenu-styles'] == 'closed-menu'" >
              <label for="switcher-closed-menu" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold " >
                Closed
                Menu</label>
            </div>
            <div class="flex">
              <input type="radio" name="sidemenu-layout-styles" class="ti-form-radio" id="switcher-icontext-menu" (click)="SideMenus('icon-text-close', 'icontext')"
              [checked]="localdata['ynex-sidemenu-styles'] == 'icon-text'">
              <label for="switcher-icontext-menu" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold ">Icon
                Text</label>

            </div>
            <div class="flex">
              <input type="radio" name="sidemenu-layout-styles" class="ti-form-radio" id="switcher-icon-overlay" (click)="SideMenus('icon-overlay-close', 'overlay')"
              [checked]="localdata['ynex-sidemenu-styles'] == 'icon-overlay'">
              <label for="switcher-icon-overlay" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold ">Icon
                Overlay</label>
            </div>
            <div class="flex">
              <input type="radio" name="sidemenu-layout-styles" class="ti-form-radio" id="switcher-detached" (click)="SideMenus('detached-close', 'detached')"
              [checked]="localdata['ynex-sidemenu-styles'] == 'detached'">
              <label for="switcher-detached"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold ">Detached</label>
            </div>
            <div class="flex">
              <input type="radio" name="sidemenu-layout-styles" class="ti-form-radio" id="switcher-double-menu" (click)="SideMenus('double-menu-open', 'doublemenu')"
              [checked]="localdata['ynex-sidemenu-styles'] == 'double-menu'">
              <label for="switcher-double-menu" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Double
                Menu</label>
            </div>
          </div>
          <div class="px-4 text-secondary text-xs"><b class="me-2">Note:</b>Navigation menu styles won't work
            here.</div>
        </div>
        <div>
          <p class="switcher-style-head">Page Styles:</p>
          <div class="grid grid-cols-3  switcher-style">
            <div class="flex">
              <input type="radio" name="data-page-styles" class="ti-form-radio" id="switcher-regular"   (click)="PageChange('regular')"
              [checked]="localdata['ynex-page-mode'] != 'classic'">
              <label for="switcher-regular"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Regular</label>
            </div>
            <div class="flex">
              <input type="radio" name="data-page-styles" class="ti-form-radio" id="switcher-classic" (click)="PageChange('classic')"
              [checked]="localdata['ynex-page-mode'] == 'classic'">
              <label for="switcher-classic"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Classic</label>
            </div>
            <div class="flex">
              <input type="radio" name="data-page-styles" class="ti-form-radio" id="switcher-modern" (click)="PageChange('modern')"
              [checked]="localdata['ynex-page-mode'] == 'modern'">
              <label for="switcher-modern"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold"> Modern</label>
            </div>
          </div>
        </div>
        <div>
          <p class="switcher-style-head">Layout Width Styles:</p>
          <div class="grid grid-cols-3 switcher-style">
            <div class="flex">
              <input type="radio" name="layout-width" class="ti-form-radio" id="switcher-full-width"   (click)="WidthChange('full-width')"
              [checked]="localdata['ynex-width-mode'] != 'boxed'">
              <label for="switcher-full-width"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">FullWidth</label>
            </div>
            <div class="flex">
              <input type="radio" name="layout-width" class="ti-form-radio" id="switcher-boxed"   (click)="WidthChange('boxed')"
              [checked]="localdata['ynex-width-mode'] == 'boxed'">
              <label for="switcher-boxed" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Boxed</label>
            </div>
          </div>
        </div>
        <div>
          <p class="switcher-style-head">Menu Positions:</p>
          <div class="grid grid-cols-3  switcher-style">
            <div class="flex">
              <input type="radio" name="data-menu-positions" class="ti-form-radio" id="switcher-menu-fixed"  (click)="MenuChange('fixed')"
              [checked]="localdata['ynex-menu-mode'] != 'scrollable'">
              <label for="switcher-menu-fixed"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Fixed</label>
            </div>
            <div class="flex">
              <input type="radio" name="data-menu-positions" class="ti-form-radio" id="switcher-menu-scroll"  (click)="MenuChange('scrollable')"
              [checked]="localdata['ynex-menu-mode'] == 'scrollable'">
              <label for="switcher-menu-scroll"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Scrollable </label>
            </div>
          </div>
        </div>
        <div>
          <p class="switcher-style-head">Header Positions:</p>
          <div class="grid grid-cols-3 switcher-style">
            <div class="flex">
              <input type="radio" name="data-header-positions" class="ti-form-radio" id="switcher-header-fixed"  (click)="HeaderChange('fixed')"
              [checked]="localdata['ynex-header-mode'] != 'scrollable'">
              <label for="switcher-header-fixed" class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">
                Fixed</label>
            </div>
            <div class="flex">
              <input type="radio" name="data-header-positions" class="ti-form-radio" id="switcher-header-scroll" (click)="HeaderChange('scrollable')"
              [checked]="localdata['ynex-header-mode'] == 'scrollable'">
              <label for="switcher-header-scroll"
                class="text-defaultsize text-defaulttextcolor dark:text-defaulttextcolor/70 ms-2  font-semibold">Scrollable
              </label>
            </div>
          </div>
        </div>
    
      </div>
      <div id="switcher-2" class="hidden" role="tabpanel" aria-labelledby="switcher-item-2">
        <div class="theme-colors">
          <p class="switcher-style-head">Menu Colors:</p>
          <div class="flex switcher-style space-x-3 rtl:space-x-reverse">
            <div class="hs-tooltip ti-main-tooltip ti-form-radio switch-select ">
              <input class="hs-tooltip-toggle ti-form-radio color-input color-white" type="radio" name="menu-colors"
                id="switcher-menu-light"  (click)="menuTheme('light')"
                [checked]="localdata['ynex-menu-mode'] == 'light'">
              <span
                class="hs-tooltip-content ti-main-tooltip-content !py-1 !px-2 !bg-black text-xs font-medium !text-white shadow-sm dark:!bg-black"
                role="tooltip">
                Light Menu
              </span>
            </div>
            <div class="hs-tooltip ti-main-tooltip ti-form-radio switch-select ">
              <input class="hs-tooltip-toggle ti-form-radio color-input color-dark" type="radio" name="menu-colors"
                id="switcher-menu-dark" (click)="menuTheme('dark')"
                [checked]="localdata['ynex-menu-mode'] == 'dark'">
              <span
                class="hs-tooltip-content ti-main-tooltip-content !py-1 !px-2 !bg-black text-xs font-medium !text-white shadow-sm dark:!bg-black"
                role="tooltip">
                Dark Menu
              </span>
            </div>
            <div class="hs-tooltip ti-main-tooltip ti-form-radio switch-select ">
              <input class="hs-tooltip-toggle ti-form-radio color-input color-primary" type="radio" name="menu-colors"
                id="switcher-menu-primary"   (click)="menuTheme('color')"
                [checked]="localdata['ynex-menu-mode'] == 'color'">
              <span
                class="hs-tooltip-content ti-main-tooltip-content !py-1 !px-2 !bg-black text-xs font-medium !text-white shadow-sm dark:!bg-black"
                role="tooltip">
                Color Menu
              </span>
            </div>
            <div class="hs-tooltip ti-main-tooltip ti-form-radio switch-select ">
              <input class="hs-tooltip-toggle ti-form-radio color-input color-gradient" type="radio" name="menu-colors"
                id="switcher-menu-gradient"   (click)="menuTheme('gradient')"
                [checked]="localdata['ynex-menu-mode'] == 'gradient'">
              <span
                class="hs-tooltip-content ti-main-tooltip-content !py-1 !px-2 !bg-black text-xs font-medium !text-white shadow-sm dark:!bg-black"
                role="tooltip">
                Gradient Menu
              </span>
            </div>
            <div class="hs-tooltip ti-main-tooltip ti-form-radio switch-select ">
              <input class="hs-tooltip-toggle ti-form-radio color-input color-transparent" type="radio" name="menu-colors"
                id="switcher-menu-transparent"  (click)="menuTheme('transparent')"
                [checked]="localdata['ynex-menu-mode'] == 'transparent'">
              <span
                class="hs-tooltip-content ti-main-tooltip-content !py-1 !px-2 !bg-black text-xs font-medium !text-white shadow-sm dark:!bg-black"
                role="tooltip">
                Transparent Menu
              </span>
            </div>
          </div>
          <div class="px-4 text-[#8c9097] dark:text-white/50 text-[.6875rem]"><b class="me-2">Note:</b>If you want to change color Menu
            dynamically
            change from below Theme Primary color picker.</div>
        </div>
        <div class="theme-colors">
          <p class="switcher-style-head">Header Colors:</p>
          <div class="flex switcher-style space-x-3 rtl:space-x-reverse">
            <div class="hs-tooltip ti-main-tooltip ti-form-radio switch-select ">
              <input class="hs-tooltip-toggle ti-form-radio color-input color-white !border" type="radio" name="header-colors"
                id="switcher-header-light"  (click)="headerTheme('light')"
                [checked]="localdata['ynex-header-mode'] == 'light'">
              <span
                class="hs-tooltip-content ti-main-tooltip-content !py-1 !px-2 !bg-black text-xs font-medium !text-white shadow-sm dark:!bg-black"
                role="tooltip">
                Light Header
              </span>
            </div>
            <div class="hs-tooltip ti-main-tooltip ti-form-radio switch-select ">
              <input class="hs-tooltip-toggle ti-form-radio color-input color-dark" type="radio" name="header-colors"
                id="switcher-header-dark"  (click)="headerTheme('dark')"
                [checked]="localdata['ynex-header-mode'] == 'dark'">
              <span
                class="hs-tooltip-content ti-main-tooltip-content !py-1 !px-2 !bg-black text-xs font-medium !text-white shadow-sm dark:!bg-black"
                role="tooltip">
                Dark Header
              </span>
            </div>
            <div class="hs-tooltip ti-main-tooltip ti-form-radio switch-select ">
              <input class="hs-tooltip-toggle ti-form-radio color-input color-primary" type="radio" name="header-colors"
                id="switcher-header-primary"    (click)="headerTheme('color')"
                [checked]="localdata['ynex-header-mode'] == 'color'">
              <span
                class="hs-tooltip-content ti-main-tooltip-content !py-1 !px-2 !bg-black text-xs font-medium !text-white shadow-sm dark:!bg-black"
                role="tooltip">
                Color Header
              </span>
            </div>
            <div class="hs-tooltip ti-main-tooltip ti-form-radio switch-select ">
              <input class="hs-tooltip-toggle ti-form-radio color-input color-gradient" type="radio" name="header-colors"
                id="switcher-header-gradient"   (click)="headerTheme('gradient')"
                [checked]="localdata['ynex-header-mode'] == 'gradient'">
              <span
                class="hs-tooltip-content ti-main-tooltip-content !py-1 !px-2 !bg-black text-xs font-medium !text-white shadow-sm dark:!bg-black"
                role="tooltip">
                Gradient Header
              </span>
            </div>
            <div class="hs-tooltip ti-main-tooltip ti-form-radio switch-select ">
              <input class="hs-tooltip-toggle ti-form-radio color-input color-transparent" type="radio"
                name="header-colors" id="switcher-header-transparent"        (click)="headerTheme('transparent')"
                [checked]="localdata['ynex-header-mode'] == 'transparent'">
              <span
                class="hs-tooltip-content ti-main-tooltip-content !py-1 !px-2 !bg-black text-xs font-medium !text-white shadow-sm dark:!bg-black"
                role="tooltip">
                Transparent Header
              </span>
            </div>
          </div>
          <div class="px-4 text-[#8c9097] dark:text-white/50 text-[.6875rem]"><b class="me-2">Note:</b>If you want to change color
            Header dynamically
            change from below Theme Primary color picker.</div>
        </div>
        <div class="theme-colors">
          <p class="switcher-style-head">Theme Primary:</p>
          <div class="flex switcher-style space-x-3 rtl:space-x-reverse">
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio color-input color-primary-1" type="radio" name="theme-primary"
                id="switcher-primary"  (click)="primary('58 88 146')"
                checked>
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio color-input color-primary-2" type="radio" name="theme-primary"
                id="switcher-primary1"  (click)="primary('92 144 163')"
                [checked]="localdata['ynex-primary-mode'] == '92 144 163'">
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio color-input color-primary-3" type="radio" name="theme-primary"
                id="switcher-primary2"  (click)="primary('161 90 223')"
                [checked]="localdata['ynex-primary-mode'] == '161 90 223'">
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio color-input color-primary-4" type="radio" name="theme-primary"
                id="switcher-primary3"  (click)="primary('78 172 76')"
                [checked]="localdata['ynex-primary-mode'] == '78 172 76'">
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio color-input color-primary-5" type="radio" name="theme-primary"
                id="switcher-primary4"     (click)="primary('223 90 90')"
                [checked]="localdata['ynex-primary-mode'] == '223 90 90'">
            </div>
            <div class="ti-form-radio switch-select ps-0 mt-1 color-primary-light">
              <div class="theme-container-primary"></div>
              <div class="pickr-container-primary">

                <div class="pickr">
               
                 <button type="button" class="pcr-button color-primary-light" role="button" aria-label="toggle color picker dialog" style="--pcr-color: rgba(132, 90, 223, 1);"  
                  id="lightPrimaryColorID"
                  data-id="primary"
                  data-id1="primary-rgb"
                  [style.background]="color1"
                  [cpAlphaChannel]="'disabled'"
                  [cpOutputFormat]="'hex'"
                  [(colorPicker)]="color1"
                  (cpSliderDragEnd)="dynamicLightPrimary($event)">  </button>
           
               
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="theme-colors">
          <p class="switcher-style-head">Theme Background:</p>
          <div class="flex switcher-style space-x-3 rtl:space-x-reverse">
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio color-input color-bg-1" type="radio" name="theme-background"
                id="switcher-background"  (click)="
                background(
                 '55 67 98','55 67 98', '64 76 107','50 62 93',
                  'dark',
                  'dark'
                )
              "
              [checked]="localdata['ynex-background-mode-dark'] == '50 62 93'">
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio color-input color-bg-2" type="radio" name="theme-background"
                id="switcher-background1"   (click)="
                background(
                  '86 98 55','86 98 55',  '95 107 64','81 93 50',
                  'dark',
                  'dark'
                )
              "
              [checked]="localdata['ynex-background-mode-dark'] == '81 93 50'">
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio color-input color-bg-3" type="radio" name="theme-background"
                id="switcher-background2"  (click)="
                background(
                '84 55 98','84 55 98',  '93 64 107','79 50 93',
                  'dark',
                  'dark'
                )
              "
              [checked]="localdata['ynex-background-mode-dark'] == '79 50 93'">
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio color-input color-bg-4" type="radio" name="theme-background"
                id="switcher-background3" (click)=" background(
                  '55 93 98','55 93 98','64 101 107','50 87 93',
                  'dark',
                  'dark'
                )
              "
              [checked]="localdata['ynex-background-mode-dark'] == '50 87 93'">
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio color-input color-bg-5" type="radio" name="theme-background"
                id="switcher-background4"  (click)="
                background(
                 '98 55 55','98 55 55', '107 64 64','93 50 50',
                  'dark',
                  'dark'
                )
              "
              [checked]="localdata['ynex-background-mode-dark'] == '93 50 50'">
            </div>
            <div class="ti-form-radio switch-select ps-0 mt-1 color-bg-transparent">
              <div class="theme-container-background hidden"></div>
              <div class="pickr-container-background">
              <div class="pickr">
                <button
                class="pcr-button color-bg-transparent"
                id="transparentBgColorID"
                data-id3="light"
                data-id5="bg"
                data-id6="bg"
                [cpToggle]="true"
                [style.background]="color4"
                [cpAlphaChannel]="'disabled'"
                [cpOutputFormat]="'hex'"
                [(colorPicker)]="color4"
                (cpSliderDragEnd)="dynamicTranparentBgPrimary($event)"
              > </button>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div class="menu-image theme-colors">
          <p class="switcher-style-head">Menu With Background Image:</p>
          <div class="flex switcher-style space-x-3 rtl:space-x-reverse flex-wrap gap-3">
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio bgimage-input bg-img1" type="radio" name="theme-images" id="switcher-bg-img"     (click)="ImageTheme('bgimg1')"
              [checked]="localdata['ynex-image'] == 'bgimg1'">
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio bgimage-input bg-img2" type="radio" name="theme-images" id="switcher-bg-img1"     (click)="ImageTheme('bgimg2')"
              [checked]="localdata['ynex-image'] == 'bgimg2'">
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio bgimage-input bg-img3" type="radio" name="theme-images" id="switcher-bg-img2"   (click)="ImageTheme('bgimg3')"
              [checked]="localdata['ynex-image'] == 'bgimg3'">
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio bgimage-input bg-img4" type="radio" name="theme-images" id="switcher-bg-img3"   (click)="ImageTheme('bgimg4')"
              [checked]="localdata['ynex-image'] == 'bgimg4'">
            </div>
            <div class="ti-form-radio switch-select">
              <input class="ti-form-radio bgimage-input bg-img5" type="radio" name="theme-images" id="switcher-bg-img4" (click)="ImageTheme('bgimg5')"
              [checked]="localdata['ynex-image'] == 'bgimg5'">
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="ti-offcanvas-footer sm:flex justify-between">
      <a href="javascript:void(0);" id="reset-all" class="w-full ti-btn ti-btn-danger-full m-1"  (click)="reset()">Reset</a>
    </div>
  </div>
  <!-- ========== END Switcher  ========== -->