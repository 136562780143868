<app-switcher></app-switcher>

<div class="page">
    <app-header></app-header>
    <app-sidebar appHoverEffectSidebar></app-sidebar>
<div class="content">
    <div class="main-content" (click)="togglesidemenuBody()">
        <router-outlet></router-outlet>
        </div>
</div>
<!-- <app-footer></app-footer> -->
<app-tab-to-top></app-tab-to-top>

</div>
<div id="responsive-overlay" (click)="clearToggle()"></div>